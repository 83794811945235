import React, { FunctionComponent, useState } from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Pill from "../../components/Pill"
import Container from "../../components/Container"
import { graphql } from "gatsby"
import styles from "./index.module.css"
import Callout from "../../components/home/Callout"
import NotifyForm from "../../components/NotifyForm"
import CompTable from "../../components/pricing/CompTable"
import Plan from "../../components/pricing/Plan"
import TrustedBy from "../../components/pricing/TrustedBy"
import BestBits from "../../components/pricing/BestBits"

interface QueryResult {
  mdx: {
    frontmatter: {
      title: string
      seo: {
        title: string
        description: string
      }
      pricing: {
        selfMonthly: number
        selfQuarterly: number
        assisted: number
        sale: number
        perContact: number
      }
      credits: {
        monthly: number
        quarterly: number
        trial: number
      }
      trialPeriod: number
    }
  }
}

interface Props {
  data: QueryResult
}

const PricingPage: FunctionComponent<Props> = ({ data }) => {
  const page = data.mdx.frontmatter
  const [option, setOption] = useState('self-30');
  const [period, setPeriod] = useState('monthly');
  function handleOptionChange(e) {
    setOption(e.target.value);
  }
  function handlePeriodChange(e) {
    setPeriod(e.target.value);
  }

  return (
    <Layout>
      <Seo
        description={page.seo.description}
        pathname="/pricing"
        title={page.seo.title}
      />

      <div className={styles.root}>


      <header className={`hero`} >
        <Pill bgClass="bg-black" variant="bottom-right">
          <div className="px-6 py-24 relative z-10">
            <div className="container flex flex-col flex-no-wrap items-center mx-auto text-center text-white">
              <div className="mb-8 text-white">
                <h1 className="px-6">Features to level up your partner discovery</h1>
              </div>

              <div className="mb-10" style={{ maxWidth: "65ch" }}>
                <p className="md:text-4">
                  Grow your ultimate partner base.
                </p>
                <p className="md:text-4">
                  Reveal your competitors’ best converting partners.
                </p>
                <p className="md:text-4">
                  Refine your partnership strategy with industry intelligence.
                </p>
              </div>

              <NotifyForm />

            </div>
          </div>
        </Pill>
      </header>

        <BestBits />

      <TrustedBy />

      <Pill bgClass="bg-green-100" variant="top-left">
        <div className="px-6 pt-12 md:pt-24">
          <div className="container flex flex-col flex-no-wrap items-center mx-auto text-center relative">
            {/*<div className="mb-2 md:mb-8">*/}
              <h2>Choose the service for you</h2>
            {/*</div>*/}
          </div>
        </div>
      </Pill>
      <Pill bgClass="bg-green-100 mb-24" variant="bottom-right">
          <div className="px-6 pt-12 pb-32 md:pb-40">
            <div className="container flex flex-col flex-no-wrap items-center mx-auto text-center relative">
              <div className="mb-8">
                <CompTable />
                <NotifyForm />
              </div>
            </div>
          </div>
        </Pill>
      <section className="px-6 py-16 pt-56 -mt-56 bg-blue">
        <div className="container flex flex-col flex-no-wrap items-center mx-auto text-center relative">
          <div className="mb-8 flex flex-col items-center">
            <h2 className="text-white px-12 w-full md:w-2/3  center">Accelerate your discovery today with our range of plans</h2>
            <div className="flex flex-wrap justify-center gap-4 py-8">
              <Callout useTilt="inverted" useDirection="inverted" useTheme="inverted" hideOnMobile>
                <span className="inline-block text-3">
                  <strong className="font-black">Level-up plans<br />any time</strong>.
                </span>
              </Callout>
            <Plan title="Starter" price="800" description="An entry tool for those doing light discovery" sc={1} scextra={650} cc={50} ccextra={30} ec={true} />
            <Plan title="Pro" price="2,700" description="For partner managers growing a multiple-region program" sc={4} scextra={550} cc={200} ccextra={25} ec={true}/>
            <Plan title="Scale" price="6,000" description="To maassively grow accrosss multiple region and niches" sc={10} scextra={500} cc={500} ccextra={20} ec={true} />
            <Plan title="Custom" description="For multi-brand Agencies or global Enterprise looking to dominate."/>
            </div>
            <div className="text-3 text-white py-2" id="minorder"><strong>*</strong> Plans are subject to a minimum annual subscription term.</div>
          </div>
        </div>
      </section>

      </div>




    </Layout>
  )
}

export const query = graphql`
  query Pricing {
    mdx(fileAbsolutePath: { regex: "//static/pricing-index/" }) {
      frontmatter {
        title
        seo {
          description
          title
        }
        pricing {
          selfMonthly
          selfQuarterly
          assisted
          sale
          perContact
        }
        credits {
          monthly
          quarterly
          trial
        }
        trialPeriod
      }
    }
  }
`

export default PricingPage
