import React from "react"
import s from "./Card.module.css"

export default function Card({children, visual, alt, title, reverse }) {
  return (
    <div className={`${s.root} ${reverse && 'flex-row-reverse '} mx-2 sm:mx-8 my-8 md:m-20 flex-col md:flex-row`}>
      <div className={`md:w-2/5 relative z-10 flex align-middle pb-0 md:pb-16 pt-6 px-6 xs:pt-8 xs:px-8 md:pt-16 md:px-16 md:pr-0 relative`}>
        <div className="flex flex-col h-full">
          <h2>{title}</h2>
          <div className="flex flex-col justify-center flex-auto">
            <ul className="list-checked text-2 md:text-3 pt-4">
              {children}
            </ul>
          </div>
        </div>
      </div>
      <div className={["md:w-3/5", "relative", "z-10", "relative", "p-1", "flex", "items-center", s.bg, reverse && s.bgReverse].join(" ")}>
        <svg className={[s.bgAlt, "block md:hidden"].join(" ")} viewBox="0 0 127 127"  fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="63.5" cy="63.5" r="63.5"/></svg>
        <img src={visual} alt={alt} className="w-full z-10 relative " />
      </div>
    </div>
)
}