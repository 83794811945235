import React from "react"
import s from "./CompTable.module.css"
import check from '!url-loader!../../images/check.svg'

export default function CompTable() {
  return (
    <div className={[s.root, 'mb-10'].join(" ")}>
      <table className={[s.tableRoot, "hidden", "md:block", "md:text-2 lg:text-3"].join(" ")}>
        <tr className={s.row}>
          <th width="40%" className={[s.cellNoBorder, s.cell].join(" ")}>
            <div
              className={[s.wrapper, s.wrapperNoPadding, s.wrapperTextLeft].join(" ")}>
              <h2>Features</h2></div>
          </th>
          <th width="30%" className={[s.cellNoBorder, s.cell].join(" ")}>
            <div
              className={[s.wrapper, s.wrapperNoPadding, s.wrapperTextLeft].join(" ")}><h3>Assisted</h3>
            </div>
          </th>
          <th width="30%" className={[s.cellNoBorder, s.cell].join(" ")}>
            <div
              className={[s.wrapper, s.wrapperNoPadding, s.wrapperTextLeft].join(" ")}><h3>Managed</h3>
            </div>
          </th>
        </tr>
        <tr className={s.row}>
          <td className={s.cell}>
            <div className={s.wrapper} />
          </td>
          <td className={s.cell}>
            <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}>We run your search.
              You review your search data, select prospects you like, and Breezy provide their contacts.
            </div>
          </td>
          <td className={s.cell}>
            <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}>We run your search,
              review and organise the data and share high potential prospects for you to engage.
            </div>
          </td>
        </tr>
        <tr className={s.row}>
          <td className={s.cell}>
            <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}>Dedicated discovery
              success manager
            </div>
          </td>
          <td className={s.cell}>
            <div className={s.wrapper}><span className={s.check} /></div>
          </td>
          <td className={s.cell}>
            <div className={s.wrapper}><span className={s.check} /></div>
          </td>
        </tr>
        <tr className={s.row}>
          <td className={s.cell}>
            <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}>Help with Live Search
              setup
            </div>
          </td>
          <td className={s.cell}>
            <div className={s.wrapper}><span className={s.check} /></div>
          </td>
          <td className={s.cell}>
            <div className={s.wrapper}><span className={s.check} /></div>
          </td>
        </tr>
        <tr className={s.row}>
          <td className={s.cell}>
            <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}>Training on platform
            </div>
          </td>
          <td className={s.cell}>
            <div className={s.wrapper}><span className={s.check} /></div>
          </td>
          <td className={s.cell}>
            <div className={s.wrapper}><span className={s.check} /></div>
          </td>
        </tr>
        <tr className={s.row}>
          <td className={s.cell}>
            <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}>We organise and tag
              results that have a high potential based on your challenges into a bespoke PRM format.
            </div>
          </td>
          <td className={s.cell}>
            <div className={s.wrapper} />
          </td>
          <td rowSpan="2" className={s.cell}>
            <div className={s.wrapper}><span className={s.check} /> £375 per 50 results.</div>
          </td>
        </tr>
        <tr className={s.row}>
          <td className={s.cell}>
            <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}>We uncover and send
              contact details of high-quality partner prospects.
            </div>
          </td>
          <td className={[s.cell, s.cellReset].join(" ")}>
            <div className={s.wrapper}><span className={s.check} /> £2.50 per contact.</div>
          </td>
        </tr>
        <tr className={s.row}>
          <td className={s.cell}>
            <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}>We identify relevant
              prospects for brand-to-brand referral and strategic partnership.
            </div>
          </td>
          <td className={s.cell}>
            <div className={s.wrapper}><span className={s.check} /></div>
          </td>
          <td className={s.cell}>
            <div className={s.wrapper}><span className={s.check} /></div>
          </td>
        </tr>
        <tr className={s.row}>
          <td className={s.cell}>
            <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}>We manage outreach
              campaigns to prospects on behalf of your brand.
            </div>
          </td>
          <td className={s.cell}>
            <div className={s.wrapper} />
          </td>
          <td className={s.cell}>
            <div className={s.wrapper}><span className={s.check} />Optional.</div>
          </td>
        </tr>
        <tr className={s.row}>
          <td className={s.cell}>
            <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}><h3>Key
              difference</h3></div>
          </td>
          <td className={s.cell}>
            <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}>You want to be
              hands-on and need partial assistance with your partner recruitment.
            </div>
          </td>
          <td className={s.cell}>
            <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}>You want to be
              hands-off so Breezy becomes your partner recruitment agency up to the point of negotiation. <br/><br/>
            </div>
          </td>
        </tr>
      </table>

      <div className={[s.cardRoot, s.cardRootPrimary, "block", "md:hidden mb-6"].join(" ")}>
        <div
          className={[s.wrapper, s.wrapperNoPadding, s.wrapperTextLeft].join(" ")}><h3>Assisted</h3>
        </div>
        <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}>We run your search.
          You review your search data, select prospects you like, and Breezy provide their contacts.
        </div>
        <ul>
          <li className={[s.wrapper, s.wrapperTextLeft].join(" ")}>Dedicated discovery
            success manager
          </li>
          <li className={[s.wrapper, s.wrapperTextLeft].join(" ")}>Help with Live Search
            setup
          </li>
          <li className={[s.wrapper, s.wrapperTextLeft].join(" ")}>Training on platform
          </li>
          <li className={[s.wrapper, s.wrapperTextLeft, s.false].join(" ")}>We organise and tag
            results that have a high potential based on your challenges into a bespoke PRM format.
          </li>
          <li className={[s.wrapper, s.wrapperTextLeft].join(" ")}>We uncover and send
            contact details of high-quality partner prospects. <strong>£2.50 per contact.</strong>
          </li>
          <li className={[s.wrapper, s.wrapperTextLeft].join(" ")}>We identify relevant
            prospects for brand-to-brand referral and strategic partnership.
          </li>
          <li className={[s.wrapper, s.wrapperTextLeft, s.false].join(" ")}>We manage outreach
            campaigns to prospects on behalf of your brand.
          </li>
        </ul>
        <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}><strong>Key
          difference</strong>
          You want to be hands-on and need partial assistance with your partner recruitment.
        </div>
      </div>
      <div className={[s.cardRoot, s.cardRootSecondary, "xs:block","sm:block", "md:hidden"].join(" ")}>
        <div
          className={[s.wrapper, s.wrapperNoPadding, s.wrapperTextLeft].join(" ")}><h3>Managed</h3>
        </div>
        <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}>
          We run your search, review and organise the data and share high potential prospects for you to engage.
        </div>
        <ul>
          <li className={[s.wrapper, s.wrapperTextLeft].join(" ")}>Dedicated discovery
            success manager
          </li>
          <li className={[s.wrapper, s.wrapperTextLeft].join(" ")}>Help with Live Search
            setup
          </li>
          <li className={[s.wrapper, s.wrapperTextLeft].join(" ")}>Training on platform
          </li>
          <li className={[s.wrapper, s.wrapperTextLeft].join(" ")}>We organise and tag
            results that have a high potential based on your challenges into a bespoke PRM format.
          </li>
          <li className={[s.wrapper, s.wrapperTextLeft].join(" ")}>We uncover and send
            contact details of high-quality partner prospects. <strong>£375 per 50 results.</strong>
          </li>
          <li className={[s.wrapper, s.wrapperTextLeft].join(" ")}>We identify relevant
            prospects for brand-to-brand referral and strategic partnership.
          </li>
          <li className={[s.wrapper, s.wrapperTextLeft].join(" ")}>We manage outreach
            campaigns to prospects on behalf of your brand. <strong>Optional.</strong>
          </li>
        </ul>
        <div className={[s.wrapper, s.wrapperTextLeft].join(" ")}><strong>Key
          difference</strong>
          You want to be hands-off so Breezy becomes your partner recruitment agency up to the point of negotiation.
        </div>
      </div>
    </div>
)
}
