import React, { FunctionComponent } from "react"
import Arrow from "../../images/callout-arrow.svg"
import styles from "./Callout.module.css"

type Variant = "default" | "inverted"

interface Props {
  hideOnMobile?: boolean
  useTheme?: Variant
  useTilt?: Variant
  useDirection?: Variant
}

function getThemeStyle(variant?: Variant) {
  return variant === "inverted" ? styles.invertedTheme : styles.defaultTheme
}

function getTiltStyle(variant?: Variant) {
  return variant === "inverted" ? styles.invertedTilt : styles.defaultTilt
}

function getDirectionStyle(variant?: Variant) {
  return variant === "inverted" ? styles.invertedDirection : styles.defaultDirection
}

const Callout: FunctionComponent<Props> = ({hideOnMobile, children, useTheme, useTilt, useDirection }) => (
  <div
    className={`
    ${styles.root}
    ${getThemeStyle(useTheme)}
    ${getTiltStyle(useTilt)}
    ${getDirectionStyle(useDirection)}
    ${hideOnMobile && styles.hideOnMobile}
    `}
  >
    <Arrow className={`${styles.arrow}`} />
    <span className={`${styles.content}`}>{children}</span>
  </div>
)

export default Callout
