import React from "react"
import Card from './Card'
import s from "./BestBits.module.css"
import NotifyForm from "../NotifyForm"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react"
import { Navigation, Scrollbar, A11y, Mousewheel, Pagination, EffectCards, Autoplay } from "swiper"
import dataEveryLead from '../../images/DataEveryLead.png'
import filtering from '../../images/Filtering.png'
import intelligence from '../../images/MarketIntelligence.png'
import liveSearches from '../../images/Live searhces.png'
import contacts from '../../images/Contacts.png'

// import 'swiper/swiper-bundle.css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

export default function BestBits() {
  return (
    <div className={[s.root, "pt-16 md:pt-40", "md:mx-auto", "md:container"].join(" ")}>
      <h2 className="mx-10 mb-0">Breezy is the market leader in partner discovery, recruitment and insight. Here’s why…</h2>
      <div className="mb-10 md:-mx-20">
        <Swiper
          modules={[Pagination, Navigation, Autoplay, Scrollbar ]}
          spaceBetween={20}
          // slidesPerView={3}
          // direction={"vertical"}
          // mousewheel
          // effect={'cards'}
          // loop={true}
          navigation
          slidesPerView={"auto"}
          centeredSlides={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: true,
          }}
          // pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          <SwiperSlide >
            <Card alt="Breezy score | " visual={dataEveryLead} title="Powerful data on every Lead">
                <li>Proprietary relevance score</li>
                <li>Existing partnerships</li>
                <li>Similarweb traffic and audience data</li>
                <li>Moz Domain Authority</li>
                <li>Relevant ranking keywords</li>
                <li>Competitor backlinks</li>
                <li>High impact content URLs</li>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
          <Card alt="Breezy score | " visual={liveSearches} title="Live partner searches for your every need">
            <li>Tailored to your niches, products and geography</li>
            <li>Built on your keywords and competitors</li>
            <li>Thousands of leads in every search</li>
            <li><ul className="list-none list-tags">
              <li className="reset">Find: </li>
              <li className="text-1">Classic affiliates</li>
              <li className="text-1">SEO backlink partners</li>
              <li className="text-1">PR prospects</li>
              <li className="text-1">Paid content partners</li>
              <li className="text-1">Traditional publishers</li>
              <li className="text-1">Influencers</li>
              <li className="text-1">Brand referral partners</li>
              <li className="reset text-1"> ... and more!</li>
            </ul>
            </li>
        </Card>
          </SwiperSlide>
          <SwiperSlide>
        <Card alt="Breezy score | " visual={intelligence} title="Market intelligence">
            <li>Your competitors’ existing partnerships</li>
            <li>Your existing partners’ new partnerships</li>
            <li>Content strategy insights to increase your performance</li>
            <li>Insights into the most relevant Networks or SaaS for your Brand</li>
        </Card>
          </SwiperSlide>
          <SwiperSlide>
        <Card alt="Breezy score | " visual={filtering} title="Advanced filtering and funnel organisation">
            <li>Advanced filters and sorting</li>
            <li>Powerful internal search</li>
            <li>Personalised classification & tagging</li>
            <li>PRM folder sequences</li>
            <li>Hide existing partners</li>
        </Card>
          </SwiperSlide>
          <SwiperSlide>
        <Card alt="Breezy score | " visual={contacts} title="Decision makers’ contact information">
            <li>Name and job description</li>
            <li>Verified email addresses</li>
            <li>Linkedin profiles</li>
            <li>GDPR compliant</li>
        </Card>
          </SwiperSlide>
        </Swiper>
      </div>
      <NotifyForm />
    </div>
)
}
