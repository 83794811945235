import React from "react"
import s from "./TrustedBy.module.css"
import NotifyForm from "../NotifyForm"
// @ts-ignore
import logoAwin from '!url-loader!../../images/logo-awin.svg'
import logoSilverbean from '!url-loader!../../images/logo-silverbean.svg'
import logoTune from '!url-loader!../../images/logo-tune.svg'


export default function TrustedBy() {
  return (
    <div className={[s.root, "container py-12 md:py-40", "text-center", "mx-auto"].join(" ")} style={{ maxWidth: "70ch"}}>
      <h2>Trusted by</h2>
      <ul className="list-none flex px-12 md:px-0 gap-6 md:gap-16 justify-center sm:flex-row flex-col items-center">
        {/*<li><img alt="Awin" width={192} height={83} src={logoAwin} /></li>*/}
        <li><img alt="Silverbean" src={logoSilverbean} /></li>
        <li><img alt="Tune" src={logoTune} /></li>
      </ul>
    </div>
)
}
